<template>
  <div class="container">
    <div class="row text-center">
      <div class="col-12">
        <div>
          <svg
            class="illustration"
            viewBox="0 0 350 350"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
          >
            <path
              d="M175 338.198C261.335 338.198 331.324 297.788 331.324 247.94C331.324 198.092 261.335 157.682 175 157.682C88.6647 157.682 18.676 198.092 18.676 247.94C18.676 297.788 88.6647 338.198 175 338.198Z"
              fill="#F5F5F5"
            />
            <path
              d="M236.523 218.288L289.177 248.682C289.84 249.067 290.594 249.27 291.361 249.27C292.128 249.27 292.882 249.067 293.545 248.682L322.84 231.756C323.038 231.642 323.202 231.477 323.316 231.28C323.43 231.082 323.49 230.857 323.49 230.629C323.49 230.401 323.43 230.176 323.316 229.979C323.202 229.781 323.038 229.616 322.84 229.502L270.2 199.185C269.538 198.804 268.787 198.603 268.023 198.603C267.259 198.603 266.508 198.804 265.846 199.185L236.53 216.027C236.332 216.142 236.168 216.306 236.053 216.504C235.939 216.702 235.878 216.927 235.877 217.156C235.876 217.384 235.936 217.609 236.049 217.808C236.162 218.007 236.326 218.172 236.523 218.288V218.288Z"
              fill="#E0E0E0"
            />
            <path
              d="M205.473 216.858L78.8215 290.155C77.7901 290.754 76.6201 291.072 75.4277 291.078C74.2353 291.084 73.062 290.778 72.0245 290.19L39.1245 271.57C38.7828 271.374 38.4988 271.091 38.3014 270.75C38.104 270.409 38 270.022 38 269.628C38 269.234 38.104 268.847 38.3014 268.506C38.4988 268.165 38.7828 267.882 39.1245 267.685L165.538 194.675C166.566 194.084 167.73 193.77 168.916 193.762C170.103 193.755 171.271 194.055 172.307 194.633L205.445 213.204C205.769 213.385 206.04 213.649 206.229 213.968C206.419 214.288 206.52 214.652 206.523 215.023C206.526 215.395 206.43 215.76 206.246 216.083C206.061 216.405 205.794 216.673 205.473 216.858V216.858Z"
              fill="#E0E0E0"
            />
            <path
              d="M290.535 272.02C297.227 272.02 302.652 268.886 302.652 265.02C302.652 261.154 297.227 258.02 290.535 258.02C283.843 258.02 278.418 261.154 278.418 265.02C278.418 268.886 283.843 272.02 290.535 272.02Z"
              fill="#E0E0E0"
            />
            <path
              d="M290.927 154.728L290.941 247.107L320.859 229.817V137.529L290.927 154.728Z"
              fill="#E6E6E6"
            />
            <path
              d="M240.114 217.763L290.941 247.107V154.812L240.128 125.559L240.114 217.763Z"
              fill="#EBEBEB"
            />
            <path
              d="M240.114 125.468L290.941 154.812L320.859 137.529L270.053 108.269L240.114 125.468Z"
              fill="#F5F5F5"
            />
            <path
              d="M246.071 159.25L284.69 181.552C284.859 181.649 285.05 181.699 285.244 181.699C285.439 181.699 285.63 181.648 285.798 181.551C285.967 181.454 286.107 181.314 286.205 181.146C286.303 180.978 286.355 180.788 286.356 180.593V161C286.353 160.516 286.222 160.041 285.976 159.625C285.73 159.208 285.378 158.863 284.956 158.627L246.4 136.409C246.231 136.312 246.039 136.261 245.844 136.262C245.649 136.263 245.457 136.314 245.289 136.412C245.12 136.51 244.98 136.65 244.883 136.819C244.785 136.988 244.734 137.18 244.734 137.375V156.877C244.73 157.355 244.851 157.826 245.085 158.242C245.32 158.659 245.66 159.006 246.071 159.25V159.25Z"
              fill="#FAFAFA"
            />
            <path
              d="M268.933 154.343C268.8 154.342 268.67 154.306 268.555 154.238L261.737 150.318C261.58 150.208 261.47 150.042 261.429 149.855C261.387 149.668 261.417 149.472 261.513 149.306C261.608 149.139 261.762 149.014 261.945 148.956C262.128 148.897 262.325 148.908 262.5 148.988L269.318 152.915C269.453 153.004 269.556 153.133 269.613 153.284C269.67 153.434 269.678 153.6 269.635 153.755C269.592 153.911 269.501 154.049 269.375 154.149C269.249 154.25 269.094 154.308 268.933 154.315V154.343Z"
              fill="#E0E0E0"
            />
            <path
              d="M246.071 186.606L284.69 208.901C284.859 208.998 285.05 209.048 285.244 209.048C285.439 209.048 285.63 208.997 285.798 208.9C285.967 208.803 286.107 208.663 286.205 208.495C286.303 208.327 286.355 208.136 286.356 207.942V188.342C286.352 187.858 286.221 187.384 285.975 186.967C285.729 186.55 285.378 186.206 284.956 185.969L246.4 163.8C246.231 163.703 246.04 163.653 245.846 163.653C245.651 163.653 245.46 163.704 245.292 163.801C245.123 163.898 244.983 164.038 244.885 164.206C244.787 164.374 244.735 164.565 244.734 164.759V184.261C244.735 184.734 244.859 185.199 245.093 185.61C245.328 186.021 245.664 186.364 246.071 186.606Z"
              fill="#FAFAFA"
            />
            <path
              d="M268.933 181.692C268.801 181.69 268.671 181.657 268.555 181.594L261.737 177.667C261.58 177.557 261.47 177.391 261.429 177.204C261.387 177.017 261.417 176.821 261.513 176.655C261.608 176.488 261.762 176.363 261.945 176.305C262.128 176.246 262.325 176.258 262.5 176.337L269.318 180.264C269.453 180.353 269.556 180.482 269.613 180.633C269.67 180.783 269.678 180.949 269.635 181.104C269.592 181.26 269.501 181.398 269.375 181.498C269.249 181.599 269.094 181.657 268.933 181.664V181.692Z"
              fill="#E0E0E0"
            />
            <path
              d="M246.071 213.955L284.69 236.25C284.859 236.347 285.05 236.397 285.244 236.397C285.439 236.397 285.63 236.346 285.798 236.249C285.967 236.152 286.107 236.012 286.205 235.844C286.303 235.676 286.355 235.485 286.356 235.291V215.691C286.353 215.207 286.221 214.733 285.976 214.316C285.73 213.899 285.378 213.555 284.956 213.318L246.4 191.1C246.231 191.003 246.04 190.953 245.846 190.953C245.651 190.953 245.46 191.004 245.292 191.101C245.123 191.198 244.983 191.338 244.885 191.506C244.787 191.674 244.735 191.865 244.734 192.059V211.568C244.728 212.048 244.848 212.522 245.083 212.941C245.318 213.36 245.658 213.709 246.071 213.955Z"
              fill="#FAFAFA"
            />
            <path
              d="M268.933 209.041C268.801 209.043 268.67 209.009 268.555 208.943L261.737 205.016C261.65 204.966 261.574 204.899 261.513 204.82C261.452 204.74 261.407 204.649 261.381 204.553C261.355 204.456 261.348 204.355 261.361 204.255C261.374 204.156 261.407 204.06 261.457 203.973C261.507 203.886 261.574 203.81 261.654 203.749C261.733 203.688 261.824 203.643 261.921 203.618C262.018 203.592 262.12 203.587 262.219 203.601C262.318 203.615 262.414 203.649 262.5 203.7L269.318 207.62C269.405 207.67 269.481 207.737 269.542 207.816C269.603 207.896 269.648 207.987 269.674 208.083C269.7 208.18 269.707 208.281 269.694 208.381C269.681 208.48 269.648 208.576 269.598 208.663C269.531 208.78 269.434 208.877 269.317 208.943C269.2 209.01 269.068 209.044 268.933 209.041V209.041Z"
              fill="#E0E0E0"
            />
            <path
              d="M271.887 61.705V86.394L293.272 98.735V74.046L271.887 61.705Z"
              fill="#FAFAFA"
            />
            <path
              d="M274.204 63.035L293.3 74.046V96.061L274.232 85.057V63.035H274.204ZM273.07 61.033L271.915 61.733V86.422L293.3 98.735L294.455 98.035V73.381L273.042 61.033H273.07Z"
              fill="#E0E0E0"
            />
            <path
              d="M273.042 63.7L292.11 74.711V96.726L273.042 85.715V63.7ZM271.887 61.698V86.387L293.3 98.735V74.046L271.887 61.705V61.698Z"
              fill="#EBEBEB"
            />
            <path
              d="M275.023 80.745L291.2 90.083L286.419 77.651L283.115 81.578L279.727 76.762L275.023 80.745Z"
              fill="#E0E0E0"
            />
            <path
              d="M280.637 72.989C280.664 73.5011 280.813 73.9995 281.069 74.4435C281.326 74.8875 281.684 75.2646 282.114 75.544C282.926 76.013 283.591 75.628 283.591 74.69C283.562 74.1781 283.414 73.6802 283.157 73.2363C282.901 72.7925 282.543 72.4152 282.114 72.135C281.295 71.666 280.637 72.044 280.637 72.989Z"
              fill="#E0E0E0"
            />
            <path
              d="M299.677 91.588V111.258L316.708 121.093V101.423L299.677 91.588Z"
              fill="#FAFAFA"
            />
            <path
              d="M301.518 92.659L316.708 101.423V119L301.518 110.229V92.729V92.659ZM300.594 91.126L299.677 91.658V111.328L316.708 121.1L317.632 120.568V100.898L300.594 91.098V91.126Z"
              fill="#E0E0E0"
            />
            <path
              d="M300.594 93.191L315.784 101.955V119.497L300.594 110.726V93.191ZM299.677 91.588V111.258L316.708 121.1V101.43L299.677 91.63V91.588Z"
              fill="#EBEBEB"
            />
            <path
              d="M302.176 106.757L315.056 114.198L311.255 104.293L308.616 107.429L305.921 103.586L302.176 106.757Z"
              fill="#E0E0E0"
            />
            <path
              d="M306.642 100.583C306.665 100.991 306.783 101.388 306.987 101.742C307.191 102.096 307.476 102.397 307.818 102.62C308.469 102.991 308.994 102.69 308.994 101.92C308.973 101.512 308.855 101.114 308.651 100.76C308.446 100.406 308.161 100.106 307.818 99.883C307.167 99.526 306.642 99.834 306.642 100.583Z"
              fill="#E0E0E0"
            />
            <path
              d="M291.249 247.184C291.249 247.184 283.801 239.911 288.043 227.731C290.416 220.906 298.284 215.894 302.743 215.418C301.259 217.181 300.077 219.177 299.243 221.326L293.601 225.078L298.788 223.454L297.682 227.892C297.682 227.892 292.859 230.811 292.145 231.448L297.346 230.09C297.346 230.09 296.968 242.662 291.249 247.184Z"
              :fill="institusi.primary_color || '#038080'"
            />
            <path
              opacity="0.4"
              d="M291.249 247.184C291.249 247.184 283.801 239.911 288.043 227.731C290.416 220.906 298.284 215.894 302.743 215.418C301.259 217.181 300.077 219.177 299.243 221.326L293.601 225.078L298.788 223.454L297.682 227.892C297.682 227.892 292.859 230.811 292.145 231.448L297.346 230.09C297.346 230.09 296.968 242.662 291.249 247.184Z"
              fill="black"
            />
            <path
              d="M291.249 247.184C291.249 247.184 287.392 231.966 293.097 223.09C293.097 223.09 286.412 230.923 291.249 247.184Z"
              fill="white"
            />
            <path
              d="M299.768 249.242L290.668 249.13H290.577L281.477 249.242C281.477 249.242 281.372 260.442 284.83 265.034C285.789 266.252 288.022 267.134 290.633 267.134C293.244 267.134 295.491 266.273 296.443 265.034C299.873 260.456 299.768 249.242 299.768 249.242Z"
              fill="#37474F"
            />
            <path
              d="M299.768 249.242C299.768 251.958 295.673 254.142 290.612 254.142C285.551 254.142 281.456 251.937 281.456 249.242C281.456 246.547 285.558 244.342 290.612 244.342C295.666 244.342 299.768 246.526 299.768 249.242Z"
              fill="#455A64"
            />
            <path
              d="M290.612 247.94C293.762 247.94 296.429 248.948 297.374 250.348C297.604 250.024 297.731 249.639 297.738 249.242C297.738 247.303 294.546 245.742 290.612 245.742C286.678 245.742 283.486 247.317 283.486 249.242C283.492 249.64 283.622 250.027 283.857 250.348C284.795 248.948 287.462 247.94 290.612 247.94Z"
              fill="#263238"
            />
            <path
              d="M290.612 247.94C287.462 247.94 284.795 248.948 283.857 250.348C284.795 251.748 287.462 252.763 290.612 252.763C293.762 252.763 296.429 251.748 297.374 250.348C296.429 248.948 293.762 247.94 290.612 247.94Z"
              fill="#E0E0E0"
            />
            <path
              d="M290.724 250.957C290.724 250.957 287.224 250.824 284.172 246.134C282.919 244.202 282.317 243.187 282.317 243.187L287.217 242.816L281.708 241.416L279.104 237.433L283.675 237.09C281.79 236.433 279.828 236.026 277.837 235.879C277.837 235.879 273.637 228.431 272.384 227.367C275.585 227.562 278.714 228.398 281.585 229.825C284.457 231.252 287.012 233.242 289.1 235.676C296.009 243.873 291.753 251.167 290.724 250.957Z"
              :fill="institusi.primary_color || '#038080'"
            />
            <path
              d="M290.724 250.957C290.724 250.957 292.936 237.657 278.824 229.663C278.845 229.663 293.468 236.691 290.724 250.957Z"
              fill="white"
            />
            <path
              d="M290.724 250.957C290.724 250.957 293.923 251.167 297.094 247.226C298.403 245.595 299.04 244.734 299.04 244.734L294.637 243.943L299.74 243.243L302.463 239.89L298.368 239.155C300.131 238.738 301.941 238.557 303.751 238.616C303.751 238.616 308.259 232.316 309.47 231.448C306.577 231.319 303.688 231.77 300.972 232.775C298.256 233.78 295.769 235.319 293.657 237.3C286.629 244.069 289.8 251.048 290.724 250.957Z"
              :fill="institusi.primary_color || '#038080'"
            />
            <path
              opacity="0.2"
              d="M290.724 250.957C290.724 250.957 293.923 251.167 297.094 247.226C298.403 245.595 299.04 244.734 299.04 244.734L294.637 243.943L299.74 243.243L302.463 239.89L298.368 239.155C300.131 238.738 301.941 238.557 303.751 238.616C303.751 238.616 308.259 232.316 309.47 231.448C306.577 231.319 303.688 231.77 300.972 232.775C298.256 233.78 295.769 235.319 293.657 237.3C286.629 244.069 289.8 251.048 290.724 250.957Z"
              fill="black"
            />
            <path
              d="M290.724 250.957C290.724 250.957 289.982 238.756 303.45 232.862C303.45 232.862 289.597 237.825 290.724 250.957Z"
              fill="white"
            />
            <path
              d="M162.135 90.9704L158.95 89.6754L158.628 89.5424H158.593C158.022 89.3362 157.413 89.2575 156.809 89.312C156.204 89.3664 155.618 89.5526 155.093 89.8574L45.7954 152.941C43.4985 154.265 41.591 156.171 40.265 158.466C38.939 160.762 38.2414 163.366 38.2424 166.017V261.175C38.243 262.187 38.5098 263.18 39.0159 264.056C39.5221 264.931 40.2499 265.658 41.126 266.163C42.0022 266.668 42.9959 266.934 44.0073 266.933C45.0186 266.933 46.0119 266.666 46.8874 266.159L156.29 202.998C157.851 202.096 159.147 200.799 160.048 199.237C160.949 197.676 161.422 195.904 161.421 194.101V93.5254C161.423 92.8051 161.24 92.0964 160.889 91.4674L162.135 90.9704Z"
              :fill="institusi.primary_color || '#038080'"
            />
            <path
              opacity="0.3"
              d="M162.135 90.9704L158.95 89.6754L158.628 89.5424H158.593C158.022 89.3362 157.413 89.2575 156.809 89.312C156.204 89.3664 155.618 89.5526 155.093 89.8574L45.7954 152.941C43.4985 154.265 41.591 156.171 40.265 158.466C38.939 160.762 38.2414 163.366 38.2424 166.017V261.175C38.243 262.187 38.5098 263.18 39.0159 264.056C39.5221 264.931 40.2499 265.658 41.126 266.163C42.0022 266.668 42.9959 266.934 44.0073 266.933C45.0186 266.933 46.0119 266.666 46.8874 266.159L156.29 202.998C157.851 202.096 159.147 200.799 160.048 199.237C160.949 197.676 161.422 195.904 161.421 194.101V93.5254C161.423 92.8051 161.24 92.0964 160.889 91.4674L162.135 90.9704Z"
              fill="black"
            />
            <path
              d="M164.32 94.6734V195.284C164.321 197.087 163.846 198.858 162.944 200.419C162.042 201.98 160.744 203.275 159.182 204.174L49.7785 267.342C48.9022 267.847 47.9087 268.112 46.8976 268.111C45.8864 268.11 44.8933 267.844 44.0176 267.338C43.142 266.833 42.4146 266.106 41.9084 265.231C41.4022 264.355 41.135 263.363 41.1335 262.351V167.151C41.1341 164.543 41.8119 161.979 43.1005 159.71V159.71C44.0766 158.028 45.4858 156.638 47.1815 155.685L160.183 90.4384C161.278 90.4726 162.319 90.9284 163.087 91.7109C163.855 92.4933 164.292 93.542 164.306 94.6384L164.32 94.6734Z"
              :fill="institusi.primary_color || '#038080'"
            />
            <path
              opacity="0.1"
              d="M164.32 94.6734V195.284C164.321 197.087 163.846 198.858 162.944 200.419C162.042 201.98 160.744 203.275 159.182 204.174L49.7785 267.342C48.9022 267.847 47.9087 268.112 46.8976 268.111C45.8864 268.11 44.8933 267.844 44.0176 267.338C43.142 266.833 42.4146 266.106 41.9084 265.231C41.4022 264.355 41.135 263.363 41.1335 262.351V167.151C41.1341 164.543 41.8119 161.979 43.1005 159.71V159.71C44.0766 158.028 45.4858 156.638 47.1815 155.685L160.183 90.4384C161.278 90.4726 162.319 90.9284 163.087 91.7109C163.855 92.4933 164.292 93.542 164.306 94.6384L164.32 94.6734Z"
              fill="black"
            />
            <path
              d="M159.181 204.174L49.7785 267.342C48.9316 267.838 47.9714 268.106 46.9904 268.122C46.0094 268.138 45.0409 267.901 44.1785 267.433L109.502 169.118L164.319 195.277C164.32 197.081 163.845 198.853 162.943 200.415C162.041 201.976 160.744 203.273 159.181 204.174V204.174Z"
              :fill="institusi.primary_color || '#038080'"
            />
            <path
              opacity="0.2"
              d="M159.181 204.174L49.7785 267.342C48.9316 267.838 47.9714 268.106 46.9904 268.122C46.0094 268.138 45.0409 267.901 44.1785 267.433L109.502 169.118L164.319 195.277C164.32 197.081 163.845 198.853 162.943 200.415C162.041 201.976 160.744 203.273 159.181 204.174V204.174Z"
              fill="black"
            />
            <path
              d="M162.478 91.1874L117.867 184.504C116.989 186.338 115.741 187.97 114.202 189.299C112.664 190.628 110.867 191.625 108.925 192.227C106.984 192.83 104.939 193.025 102.918 192.801C100.897 192.576 98.9446 191.937 97.1823 190.923L43.1003 159.731C44.0235 158.092 45.3701 156.731 46.9993 155.79L160.182 90.4384C161.005 90.4579 161.803 90.7184 162.478 91.1874V91.1874Z"
              :fill="institusi.primary_color || '#038080'"
            />
            <path
              d="M54.3286 129.771L86.3746 111.263V88.3243L63.0856 101.162C60.4331 102.622 58.2211 104.768 56.6808 107.374C55.1404 109.981 54.3281 112.954 54.3286 115.981V129.771Z"
              :fill="institusi.primary_color || '#038080'"
            />
            <path
              opacity="0.5"
              d="M54.3286 129.771L86.3746 111.263V88.3243L63.0856 101.162C60.4331 102.622 58.2211 104.768 56.6808 107.374C55.1404 109.981 54.3281 112.954 54.3286 115.981V129.771Z"
              fill="white"
            />
            <path
              d="M186.783 40.9344L183.85 39.2754C180.125 37.1214 175.896 35.9914 171.593 36C167.29 36.0087 163.066 37.1556 159.35 39.3244L67.6986 92.7204C63.6304 95.0896 60.2547 98.4845 57.9086 102.566C55.5626 106.648 54.3281 111.273 54.3286 115.981C54.3283 113.563 54.9666 111.187 56.1791 109.094C57.3915 107.002 59.1351 105.266 61.2334 104.064C63.3318 102.861 65.7105 102.234 68.129 102.246C70.5475 102.257 72.92 102.908 75.0066 104.13L78.8426 106.377C82.8365 108.715 86.1493 112.057 88.4518 116.071C90.7543 120.086 91.9664 124.633 91.9676 129.26V265.438L85.7166 281.307L97.4556 274.16L191.823 219.406C195.174 217.463 197.956 214.673 199.889 211.315C201.821 207.957 202.837 204.15 202.834 200.275V68.7874C202.833 63.1455 201.35 57.6028 198.533 52.7145C195.716 47.8262 191.664 43.7639 186.783 40.9344V40.9344Z"
              :fill="institusi.primary_color || '#038080'"
            />
            <path
              opacity="0.7"
              d="M186.783 40.9344L183.85 39.2754C180.125 37.1214 175.896 35.9914 171.593 36C167.29 36.0087 163.066 37.1556 159.35 39.3244L67.6986 92.7204C63.6304 95.0896 60.2547 98.4845 57.9086 102.566C55.5626 106.648 54.3281 111.273 54.3286 115.981C54.3283 113.563 54.9666 111.187 56.1791 109.094C57.3915 107.002 59.1351 105.266 61.2334 104.064C63.3318 102.861 65.7105 102.234 68.129 102.246C70.5475 102.257 72.92 102.908 75.0066 104.13L78.8426 106.377C82.8365 108.715 86.1493 112.057 88.4518 116.071C90.7543 120.086 91.9664 124.633 91.9676 129.26V265.438L85.7166 281.307L97.4556 274.16L191.823 219.406C195.174 217.463 197.956 214.673 199.889 211.315C201.821 207.957 202.837 204.15 202.834 200.275V68.7874C202.833 63.1455 201.35 57.6028 198.533 52.7145C195.716 47.8262 191.664 43.7639 186.783 40.9344V40.9344Z"
              fill="white"
            />
            <path
              d="M58.1296 268.931C58.1296 268.931 64.7026 278.542 75.2656 281.727C86.6266 285.15 91.9676 277.618 91.9676 267.629V249.562L58.1296 268.931Z"
              :fill="institusi.primary_color || '#038080'"
            />
            <path
              opacity="0.5"
              d="M58.1296 268.931C58.1296 268.931 64.7026 278.542 75.2656 281.727C86.6266 285.15 91.9676 277.618 91.9676 267.629V249.562L58.1296 268.931Z"
              fill="white"
            />
            <path
              d="M112.387 172.275C112.16 172.272 111.94 172.195 111.761 172.055C111.582 171.915 111.453 171.721 111.395 171.502C111.336 171.282 111.351 171.05 111.436 170.84C111.522 170.629 111.674 170.453 111.869 170.336L181.869 129.904C182.107 129.767 182.391 129.73 182.656 129.802C182.922 129.873 183.149 130.047 183.286 130.286C183.424 130.524 183.461 130.808 183.389 131.074C183.317 131.339 183.143 131.566 182.905 131.703L112.905 172.135C112.747 172.226 112.568 172.274 112.387 172.275Z"
              fill="white"
            />
            <path
              d="M112.387 187.78C112.159 187.78 111.938 187.704 111.757 187.566C111.577 187.427 111.447 187.233 111.388 187.013C111.329 186.793 111.344 186.56 111.431 186.349C111.518 186.139 111.672 185.963 111.869 185.848L181.869 145.409C182.107 145.272 182.391 145.235 182.656 145.307C182.922 145.378 183.149 145.552 183.286 145.791C183.424 146.029 183.461 146.313 183.389 146.579C183.317 146.844 183.143 147.071 182.905 147.208L112.905 187.675C112.744 187.753 112.565 187.789 112.387 187.78V187.78Z"
              fill="white"
            />
            <path
              d="M112.387 203.278C112.159 203.278 111.938 203.202 111.757 203.064C111.577 202.925 111.447 202.731 111.388 202.511C111.329 202.291 111.344 202.058 111.431 201.847C111.518 201.637 111.672 201.461 111.869 201.346L181.869 160.914C182.105 160.791 182.38 160.764 182.636 160.839C182.893 160.914 183.11 161.084 183.243 161.315C183.377 161.546 183.416 161.82 183.353 162.079C183.29 162.338 183.129 162.563 182.905 162.706L112.905 203.138C112.747 203.228 112.569 203.277 112.387 203.278Z"
              fill="white"
            />
            <path
              d="M112.387 218.776C112.159 218.776 111.938 218.701 111.757 218.562C111.577 218.423 111.447 218.229 111.388 218.009C111.329 217.789 111.344 217.556 111.431 217.345C111.518 217.135 111.672 216.959 111.869 216.844L181.869 176.412C182.105 176.289 182.38 176.262 182.636 176.337C182.893 176.412 183.11 176.582 183.243 176.813C183.377 177.044 183.416 177.318 183.353 177.577C183.29 177.836 183.129 178.061 182.905 178.204L112.905 218.636C112.748 218.73 112.569 218.778 112.387 218.776V218.776Z"
              fill="white"
            />
            <path
              d="M112.387 234.274C112.159 234.274 111.938 234.198 111.757 234.06C111.577 233.921 111.447 233.727 111.388 233.507C111.329 233.287 111.344 233.054 111.431 232.843C111.518 232.633 111.672 232.457 111.869 232.342L181.869 191.91C182.105 191.787 182.38 191.76 182.636 191.835C182.893 191.91 183.11 192.08 183.243 192.311C183.377 192.542 183.416 192.815 183.353 193.075C183.29 193.334 183.129 193.559 182.905 193.702L112.905 234.134C112.748 234.228 112.569 234.276 112.387 234.274V234.274Z"
              fill="white"
            />
            <path
              d="M158.356 223.228C158.174 223.23 157.995 223.182 157.837 223.091C157.68 223 157.549 222.869 157.46 222.71C157.327 222.474 157.292 222.195 157.363 221.933C157.433 221.671 157.604 221.448 157.837 221.31L181.882 207.408C182.119 207.285 182.394 207.258 182.65 207.333C182.906 207.408 183.124 207.578 183.257 207.809C183.391 208.04 183.43 208.314 183.367 208.573C183.304 208.832 183.143 209.057 182.918 209.2L158.874 223.095C158.715 223.183 158.537 223.229 158.356 223.228V223.228Z"
              fill="white"
            />
            <path
              d="M166.055 94.9235L159.412 91.0735L159.356 91.0385C156.458 89.4005 152.475 89.6385 148.072 92.1935C139.21 97.3105 132.021 109.757 132.021 119.991C132.021 125.101 133.82 128.692 136.711 130.372L142.766 133.872L144.166 131.499C145.784 131.14 147.337 130.536 148.772 129.707C157.634 124.59 164.823 112.144 164.823 101.91C164.822 100.527 164.652 99.1494 164.319 97.8075L166.055 94.9235Z"
              :fill="institusi.primary_color || '#038080'"
            />
            <path
              opacity="0.15"
              d="M132.609 114.608C132.217 116.378 132.013 118.184 132 119.998C132 125.108 133.799 128.699 136.69 130.379L142.745 133.879L144.145 131.506C145.763 131.147 147.316 130.543 148.751 129.714C150.319 128.796 151.786 127.714 153.126 126.487L132.609 114.608Z"
              :fill="institusi.primary_color || '#038080'"
            />
            <path
              d="M154.372 95.8405C145.51 100.958 138.321 113.404 138.321 123.638C138.321 133.872 145.51 138.023 154.372 132.906C163.234 127.789 170.423 115.343 170.423 105.109C170.423 94.8745 163.241 90.7235 154.372 95.8405Z"
              :fill="institusi.primary_color || '#038080'"
            />
            <path
              d="M162.401 117.721L157.809 114.408L162.582 105.629C162.688 105.435 162.753 105.222 162.773 105.004C162.793 104.786 162.769 104.566 162.701 104.357C162.633 104.147 162.523 103.953 162.377 103.785C162.231 103.617 162.053 103.478 161.851 103.376C161.649 103.274 161.429 103.212 161.202 103.192C160.976 103.173 160.747 103.196 160.53 103.262C160.313 103.327 160.111 103.433 159.936 103.573C159.761 103.714 159.617 103.886 159.512 104.08L155.013 112.362L150.885 109.356C150.518 109.089 150.055 108.974 149.6 109.035C149.144 109.096 148.732 109.329 148.455 109.683C148.178 110.037 148.058 110.482 148.122 110.921C148.185 111.359 148.428 111.756 148.795 112.023L153.379 115.336L148.803 123.751C148.697 123.944 148.632 124.156 148.612 124.373C148.591 124.591 148.615 124.811 148.683 125.019C148.751 125.228 148.862 125.422 149.008 125.589C149.154 125.757 149.333 125.895 149.534 125.995C149.781 126.12 150.056 126.185 150.334 126.186C150.652 126.188 150.963 126.105 151.235 125.947C151.507 125.79 151.728 125.562 151.874 125.291L156.174 117.381L160.303 120.38C160.602 120.599 160.967 120.719 161.344 120.719C161.711 120.728 162.071 120.623 162.371 120.42C162.672 120.217 162.898 119.927 163.015 119.592C163.132 119.257 163.135 118.895 163.023 118.558C162.911 118.222 162.69 117.928 162.393 117.721H162.401Z"
              fill="#FAFAFA"
            />
          </svg>
        </div>
        <div class="mt-3">
          <h1>Registrasi Peserta Didik Gagal</h1>
          <p>
            Mohon maaf proses registrasi peserta didik gagal disimpan,<br />
            Silahkan hubungi
            <a :href="`mailto:${institusi.email}`">{{ institusi.email }}</a>
            untuk proses lebih lanjut, Terima Kasih.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      institusi: JSON.parse(localStorage.institusi) || {},
    }
  },
  mounted() {
  },
}
</script>

<style>
.container {
  height: 100vh;
}

.container .row {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.illustration {
  width: 20em;
}

@media (min-width: 768px) {
  .illustration {
    width: 30em;
  }
}
</style>
